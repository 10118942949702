@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";

// components
@import "../components";

@import "./site/index";

//aos
@import "~aos/src/sass/aos";

@media print {
  @import "./print";
}

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text {
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}
.itinerary__layout .itinerary__side__panel {
  @media screen and (min-width: 1200px) {
    flex: 1 0 30%;
    max-width: 30%;
  }
  @media screen and (min-width: 1600px) {
    flex: 1 0 25%;
    max-width: 25%;
  }
}
.itinerary__layout .itinerary__main__panel {
  @media screen and (min-width: 1200px) {
    flex: 1 0 70%;
    max-width: 70%;
  }
  @media screen and (min-width: 1600px) {
    flex: 1 0 75%;
    max-width: 75%;
  }
}
.itinerary__layout .itinerary__view__item__title {
  @media screen and (min-width: 1200px) {
    line-height: 1.1;
  }
  @media screen and (min-width: 1600px) {
    line-height: 1.5;
  }
}

.gm-style .gm-style-iw-c {
  padding-right: 12px !important;
  padding-bottom: 12px !important;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.htmlPanel a {
  text-decoration: underline;
}
#contact .htmlPanel a {
  text-decoration: none;
}
