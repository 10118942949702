.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  .tile {
    padding: 10px 0.7rem;
    //height: 100%;
  }

  &.useSlider {
    display: block;

    .tile {
      max-width: 100%;
      height: 100%;
    }
  }
}

.pageTile__content {
  overflow: unset !important;

  &:hover,
  &:focus {
    text-decoration: none;

    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    .pageTile_details {
      //background: red;
      // &.pink{
      //   background: $color-pink;
      // }
      // &.blue{
      //   background: $color-blue;
      // }
    }

    .pageTile__title,
    .pageTile_desc {
      color: $color-dark-brown !important;
    }

    .pageTile__line {
      border-top-color: white !important;
    }

    .tile__bg__container {
      transform: scale(1.02);
    }

  }
}

.tile__bg__container {
  padding: 0.3rem;
  //box-shadow: 3px 1px 12px rgba(0, 0, 0, 0.7);
  transition: all 0.2s;
  background-color: white;
  z-index: 1;
  border-radius: 5px;
}

.pageTile__title {
  font-family: $family-header;
  font-size: 1.8rem;
  text-transform: uppercase;
  //font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0;
  color: $color-dark-brown;
  letter-spacing: 3.7px;
  // &.pink{
  //   color: $color-pink;
  // }
  // &.blue{
  //   color: $color-blue;
  // }
}

.pageTile_desc {
  line-height: 1.4;
  //font-size: 0.7rem;
  font-size: 18px;
  color: $font-color;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.pageTile__line {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 70% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  border-top-color: red;
  // &.pink{
  //   border-top-color: $color-pink;
  // }
  // &.blue{
  //   border-top-color: $color-blue;
  // }
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 1rem 0.8rem;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  //background-color: #f9f6f6;
}

.pageTile_details.tile__details {
  //width: 90%;
  margin: auto;
  margin-top: -1px;
  //box-shadow: 3px 1px 12px rgba(0, 0, 0, 0.7);

}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }

}